'use strict';

(function() {
  class AddEditUserCtrl {
    constructor($scope, Auth, Site, $uibModal, $state, usSpinnerService) {
      this.$scope = $scope;
      this.Site = Site;
      this.$uibModal = $uibModal;
      this.loading = false;
      this.usSpinnerService = usSpinnerService;
      Auth.isLoggedInAsync((loggedIn)=> {
        if (loggedIn) {
          // this.init();
        }else{
          $state.go('login');
        }
      });
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }

    addUser() {
      return user=> {
        this.$scope.$emit('userAdded', user.data);
      }
    }

    openAddUserModal(data = {}, update = false) {
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/user/addEditUserModal/addEditUserModal.html',
        controller: 'AddEditUserModalCtrl',
        controllerAs: 'aEUser',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          options: {
            title: 'Add New User'
          }
        }
      });
      modalInstance.result.then(this.addUser());
    }
  }

  angular.module('windmanagerApp')
    .controller('AddEditUserCtrl', AddEditUserCtrl);
})();

